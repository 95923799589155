var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "position": "relative",
      "justify-content": "center",
      "z-index": "10"
    }
  }, [!_vm.isLoading ? _c('c-flex', {
    staticClass: "program-active",
    attrs: {
      "background-color": "#FFFCDD",
      "data-testid": "active-program",
      "w": "100%",
      "max-width": "600px",
      "bg": "#FFF",
      "flex-direction": "column",
      "padding": ['10px 12px', '10px 30px'],
      "gap": ['8px', '16px'],
      "border-radius": "0.5rem",
      "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.profile.referral'
        });
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "color": "neutral.darkGray",
      "text-align": "center",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Kode AjakSehat ")]), !!_vm.item.image ? _c('c-image', {
    attrs: {
      "src": _vm.item.image,
      "w": ['60px', '80px'],
      "object-fit": "cover",
      "border-radius": "50%"
    }
  }) : _vm._e()], 1), _c('c-divider', {
    attrs: {
      "orientation": "vertical",
      "border-color": "#F4CC46",
      "border-width": "2px",
      "margin": "0 16px"
    }
  }), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-dir": "column",
      "justify": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": ['16px', '20px'],
      "font-weight": ['500', '700'],
      "mb": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.code) + " ")]), _c('c-text', {
    attrs: {
      "color": "#555",
      "font-size": ['10px', '18px'],
      "font-weight": ['400', '500'],
      "mb": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.description) + " ")])], 1), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-chevron-right.svg'),
      "stroke": "#555555"
    }
  })], 1)], 1)], 1) : _c('c-flex', {
    attrs: {
      "data-testid": "active-program",
      "w": "100%",
      "max-width": "600px",
      "bg": "#FFF",
      "flex-direction": "column",
      "px": "1rem",
      "pb": ['0.5rem', '1rem'],
      "gap": "1rem",
      "border-radius": "0.5rem",
      "box-shadow": ['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "text-align": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['110px', '200px'],
      "h": ['30px', '48px'],
      "border-bottom-radius": "8px",
      "overflow": "hidden"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": [400, 500]
    }
  }, [_c('c-box', {
    attrs: {
      "w": "150px",
      "h": ['14px', '24px'],
      "mb": "4px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "h": ['14px', '24px'],
      "mb": "4px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "80%",
      "h": ['14px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "font-size": ['10px', '16px']
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['100px', '120px'],
      "h": ['16px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['130px', '165px'],
      "h": ['16px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }