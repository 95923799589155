<template>
  <c-flex
    position="relative"
    justify-content="center"
    z-index="10"
  >
    <c-flex
      v-if="!isLoading"
      background-color="#FFFCDD"
      data-testid="active-program"
      w="100%"
      max-width="600px"
      bg="#FFF"
      flex-direction="column"
      :padding="['10px 12px', '10px 30px']"
      :gap="['8px', '16px']"
      border-radius="0.5rem"
      :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      cursor="pointer"
      class="program-active"
      @click.native="$router.push({ name: 'client.profile.referral' })"
    >
      <c-flex
        w="100%"
      >
        <c-flex
          flex-direction="column"
          gap="8px"
          align-items="center"
        >
          <BaseText
            size-mobile="12px"
            size-desktop="16px"
            color="neutral.darkGray"
            text-align="center"
            white-space="nowrap"
          >
            Kode AjakSehat
          </BaseText>
          <c-image
            v-if="!!item.image"
            :src="item.image"
            :w="['60px', '80px']"
            object-fit="cover"
            border-radius="50%"
          />
        </c-flex>
        <c-divider
          orientation="vertical"
          border-color="#F4CC46"
          border-width="2px"
          margin="0 16px"
        />
        <c-flex
          flex-grow="1"
          flex-dir="column"
          justify="center"
        >
          <c-text
            color="primary.400"
            :font-size="['16px', '20px']"
            :font-weight="['500', '700']"
            mb="4px"
          >
            {{ item.code }}
          </c-text>
          <c-text
            color="#555"
            :font-size="['10px', '18px']"
            :font-weight="['400', '500']"
            mb="4px"
          >
            {{ item.description }}
          </c-text>
        </c-flex>
        <c-flex align-items="center">
          <inline-svg
            :src="require('@/assets/icon-chevron-right.svg')"
            stroke="#555555"
          />
        </c-flex>
      </c-flex>
    </c-flex>

    <!-- Skeleton -->
    <c-flex
      v-else
      data-testid="active-program"
      w="100%"
      max-width="600px"
      bg="#FFF"
      flex-direction="column"
      px="1rem"
      :pb="['0.5rem', '1rem']"
      gap="1rem"
      border-radius="0.5rem"
      :box-shadow="['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']"
    >
      <c-flex
        justify-content="center"
        text-align="center"
      >
        <c-box
          :w="['110px', '200px']"
          :h="['30px', '48px']"
          border-bottom-radius="8px"
          overflow="hidden"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
      </c-flex>

      <c-box
        :font-size="['12px', '18px']"
        :font-weight="[400, 500]"
      >
        <c-box
          w="150px"
          :h="['14px', '24px']"
          mb="4px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          w="100%"
          :h="['14px', '24px']"
          mb="4px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          w="80%"
          :h="['14px', '24px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
      </c-box>

      <c-flex
        justify-content="space-between"
        :font-size="['10px', '16px']"
      >
        <c-box
          :w="['100px', '120px']"
          :h="['16px', '24px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          :w="['130px', '165px']"
          :h="['16px', '24px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import VueSkeletonLoader from 'skeleton-loader-vue'
import { mapActions } from 'vuex'
import { reqGetReferral } from '@/requests/dietela-api/other'
import { CBox, CImage, CText, CFlex, CDivider } from '@chakra-ui/vue'
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'CardInfoReferral',
  components: {
    CDivider,
    BaseText,
    VueSkeletonLoader,
    CBox,
    CImage,
    CText,
    CFlex,
  },
  data() {
    return {
      isLoading: false,
      programActive: null,
      item: {
        code: '',
        description: '',
        image: '',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    formatDateV2,
    ...mapActions({
      checkActiveProgram: 'clients/checkActiveProgram',
    }),
    async init() {
      this.isLoading = true
      const res = await reqGetReferral(this.$store.getters.axios).finally(() => {
        this.isLoading = false
      })
      this.item = {
        code: res?.data?.data?.referralData?.code || '',
        description: res?.data?.data?.ReferralsMenu?.description || '',
        image: res?.data?.data?.ReferralsMenu?.image || '',
      }
    },
  },
}
</script>

<style scoped>
.program-active:hover {
  background-color: #faf5c5;
}
</style>