var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "h": ['calc(100vh - 62px)', 'auto'],
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": "16px",
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumb
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto"
    }
  }, [_c('c-text', {
    attrs: {
      "d": ['none', 'block'],
      "font-family": "Roboto",
      "font-size": "28px",
      "color": "#111",
      "font-weight": "700",
      "mb": "1.5rem",
      "text-align": _vm.role === 'client' ? 'inherit' : 'center'
    }
  }, [_vm._v(" Akun ")]), _vm.user.role === 'nutritionist' ? _c('c-box', {
    attrs: {
      "margin-bottom": "32px",
      "padding": "0 32px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": ['10px', '16px']
    }
  }, [_c('CardNutritionist', {
    attrs: {
      "name": _vm.getFullnameAndTitle("".concat(_vm.nutritionist.firstName, " ").concat(_vm.nutritionist.lastName).trim(), _vm.nutritionist.education),
      "photo": _vm.nutritionist.photoUrl,
      "service-hours": _vm.nutritionist.serviceHour,
      "ratings": _vm.nutritionist.rating,
      "educations": _vm.educationsParser(_vm.nutritionist.education, _vm.universities),
      "specializations": _vm.nutritionist.specialization || '-',
      "remaining-quota": _vm.nutritionist.remainingQuota
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c('BaseButton', {
          attrs: {
            "border-radius": "1000px",
            "width": "100%",
            "size": "medium",
            "padding": "0 50px",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push({
                name: 'client.profile.detail'
              });
            }
          }
        }, [_vm._v(" Lihat Profil ")])];
      },
      proxy: true
    }], null, false, 3757007869)
  }), _c('CardInfoReferral', {
    attrs: {
      "type": "profile"
    }
  })], 1)], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "background-color": ['#FFF', '#F2F2F2'],
      "border-radius": "1rem",
      "px": ['0.5rem', '1rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "h": "64px",
      "align-items": "center"
    }
  }, [_c('c-link', {
    attrs: {
      "as": "router-link",
      "to": _vm.user.role === 'nutritionist' ? {
        name: 'profile.edit'
      } : {
        name: 'client.profile.detail'
      },
      "w": "100%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "p": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "500"
    }
  }, [_vm._v(" Ubah Data ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/ic-chevron-right-black.svg'),
      "alt": "icon arrow right"
    }
  })], 1)], 1), _vm.role !== 'admin' && _vm.role !== 'super_admin' ? _c('c-flex', {
    attrs: {
      "h": "64px",
      "align-items": "center",
      "border-top": "1px solid #C4C4C4"
    }
  }, [_c('c-link', {
    attrs: {
      "as": "router-link",
      "to": {
        name: 'client.profile.settings'
      },
      "w": "100%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "p": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "500"
    }
  }, [_vm._v(" Ubah Password ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/ic-chevron-right-black.svg'),
      "alt": "icon arrow right"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }